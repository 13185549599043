import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  BottomButton,
  CoinsIndicator,
  Page,
  RankWithProgress,
  VersusBattleResultHero,
} from '@app/components';
import { gameService } from '@app/services';
import { useServiceState } from '@app/common/state';
import { VersusBattleResultType } from '@app/types';
import { Button } from '@app/ui-kit';

import s from './VersusBattleResultPage.module.scss';

export const VersusBattleResultPage: React.FC = () => {
  const navigate = useNavigate();
  const [battleResult, setBattleResult] =
    useState<VersusBattleResultType | null>(null);
  const { score } = useServiceState(gameService, ['score']);
  const [newScore, setNewScore] = useState(score);
  const [playerLevel, setPlayerLevel] = useState(1);

  useEffect(() => {
    const battleResultTypes = [
      VersusBattleResultType.victory,
      VersusBattleResultType.defeat,
      VersusBattleResultType.tie,
      VersusBattleResultType.escaped,
    ];

    const randomResult =
      battleResultTypes[Math.floor(Math.random() * battleResultTypes.length)];

    setBattleResult(randomResult);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (battleResult != null) {
      setTimeout(handleState, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [battleResult]);

  const handleState = () => {
    let val = score;

    switch (battleResult) {
      case VersusBattleResultType.victory:
        val += 5000;
        setPlayerLevel(10);
        break;
      case VersusBattleResultType.defeat:
        val -= 3000;
        break;
      case VersusBattleResultType.tie:
        break;
      case VersusBattleResultType.escaped:
        val -= 3000;
        break;
    }

    console.log(val);

    setNewScore(val);
  };

  return (
    <Page className={s.root} isHaveBottomNav={false}>
      {battleResult != null && (
        <VersusBattleResultHero battleResult={battleResult} />
      )}
      <div className={s.content}>
        <RankWithProgress
          variant="battleResult"
          level={playerLevel}
          currentProgress={Math.min(playerLevel, 5)}
          showProgressValues={true}
        />
        <div className={s.balance}>Tomato Balance</div>
        <CoinsIndicator
          variant="tomato"
          value={newScore}
          animateOnChange={newScore !== score ? true : false}
          fontSize={48}
        />
      </div>
      <BottomButton onClick={() => navigate('/skills/battle-result')} />
    </Page>
  );
};
