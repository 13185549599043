import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  Page,
  BackButton,
  VersusPlayerBars,
  WormSpine,
  VersusBattleWheel,
} from '@components';

import { VersusSkillItem, VersusSkillType } from '@app/types';
import { versusService } from '@app/services';
import { useServiceState } from '@app/common/state';

import underwearImg from '@media/versus/underwear.png';

import s from './VersusBattlePage.module.scss';
import { NavItem } from '@app/components/NavBars';

type GameState =
  | 'idle'
  | 'startRound'
  | 'detectForce'
  | 'hitMessage'
  | 'waiting'
  | 'fight';

export const VersusBattlePage: React.FC = () => {
  const navigate = useNavigate();
  const { roundState } = useServiceState(versusService, ['roundState']);

  useEffect(() => {
    versusService.nextState('startRound');
  }, []);

  const handleNavigate = () => {
    navigate('/battle-result');
    versusService.nextState('idle');
  };

  useEffect(() => {
    if (roundState === 'fight') {
      setTimeout(handleNavigate, 7000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roundState]);

  const playerSkills: VersusSkillItem[] = [
    { id: '1', type: VersusSkillType.power, level: 5 },
    { id: '2', type: VersusSkillType.reaction, level: 15 },
    { id: '3', type: VersusSkillType.resilience, level: 95 },
  ];

  const opponentSkills: VersusSkillItem[] = [
    { id: '1', type: VersusSkillType.power, level: 15 },
    { id: '2', type: VersusSkillType.reaction, level: 5 },
    { id: '3', type: VersusSkillType.resilience, level: 65 },
  ];

  return (
    <Page className={s.root} isHaveBottomNav={false}>
      <BackButton onClick={() => navigate(-1)} />
      <VersusPlayerBars
        ownName={'subarumax'}
        opponentName={'jefferson'}
        ownSkills={playerSkills}
        opponentSkills={opponentSkills}
        className={s.playersBar}
        score={1000}
      />
      <NavItem
        className={s.btn}
        label="Retreat"
        icon={<img src={underwearImg} width={54} alt="icon" />}
        onClick={() => {
          //TODO
        }}
        iconBg={'#FF5C4F'}
        iconBgSize={'48px'}
      />

      <WormSpine />

      <VersusBattleWheel className={s.wheel} state={roundState} />
    </Page>
  );
};
