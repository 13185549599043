import { m, AnimatePresence } from 'framer-motion';
import cn from 'classnames';

import rank1 from '@media/league/rank-1.png';
import rank2 from '@media/league/rank-2.png';
import rank3 from '@media/league/rank-3.png';

import s from './RankBadge.module.scss';

interface RankBadgeProps {
  level: number;
  fontSize?: number;
  transitionDurationSeconds?: number;
  className?: string;
}

export const RankBadge: React.FC<RankBadgeProps> = ({
  level,
  transitionDurationSeconds = 0.5,
  className,
}) => {
  // TODO not all frames are available in design, need to update this afterwards
  const getRankImage = () => {
    if (level <= 4) {
      return rank1;
    } else if (level >= 5 && level <= 9) {
      return rank2;
    } else if (level >= 10 && level <= 19) {
      return rank3;
    }

    return rank3;
  };

  const rankImage = getRankImage();

  return (
    <div className={cn(s.root, className)}>
      <AnimatePresence mode="popLayout" initial={false}>
        <m.img
          key={rankImage}
          src={rankImage}
          alt={`Rank for level ${level}`}
          className={s.rankImage}
          initial={{ opacity: 0, scale: 1.5 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{
            duration: transitionDurationSeconds,
            type: 'spring',
            stiffness: 100,
            bounce: 15,
          }}
        />
      </AnimatePresence>
      <AnimatePresence mode="popLayout" initial={false}>
        <m.div
          key={level}
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          transition={{ type: 'tween', duration: transitionDurationSeconds }}
          className={s.level}
        >
          {level}
        </m.div>
      </AnimatePresence>
    </div>
  );
};
