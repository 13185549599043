import {
  SpinePlayer,
  Skin,
  SpinePlayerConfig,
} from '@esotericsoftware/spine-player';

export interface SpineAnimationProps {
  playerContainer: HTMLElement | null;
  config: SpinePlayerConfig;
  playerSkinName: string | null;
  baseSkins?: string[];
  onLoad?: () => void;
}

export class SpineAnimation {
  private spinePlayer: SpinePlayer | null = null;
  private playerContainer: HTMLElement | null = null;
  private playerSkinName: string | null = null;
  private config: SpinePlayerConfig;
  private baseSkins: string[];
  private onLoad?: () => void;

  constructor({
    playerContainer,
    config,
    playerSkinName,
    baseSkins = [],
    onLoad,
  }: SpineAnimationProps) {
    this.playerContainer = playerContainer;
    this.config = config;
    this.playerSkinName = playerSkinName;
    this.baseSkins = baseSkins;
    this.onLoad = onLoad;

    this.initPlayer();
  }
  public get player() {
    return this.spinePlayer;
  }

  public get skeleton() {
    return this.spinePlayer?.skeleton;
  }

  public get skins() {
    return this.skeleton?.data.skins.map((skin) => skin.name);
  }

  public get animations() {
    return this.skeleton?.data.animations.map((animation) => animation.name);
  }

  private initPlayer() {
    const playerConfig = {
      ...this.config,

      success: (player: SpinePlayer) => {
        console.log('Player loaded successfully!');
        this.onLoad?.();

        this.spinePlayer = player;

        if (this.playerSkinName) {
          this.applyCombinedSkins(this.playerSkinName);
        }

        player.play();
      },
      error: (player: SpinePlayer, msg: string) => {
        console.error('Player failed to load:', msg);
      },
    };

    if (this.playerContainer) {
      new SpinePlayer(this.playerContainer, playerConfig);
    }
  }

  private createBaseCombinedSkin() {
    const skeleton = this.skeleton;

    if (skeleton) {
      const skeletonData = skeleton.data;
      const skin = new Skin('combined-skin');

      this.baseSkins.forEach((baseSkin) => {
        const foundSkin = skeletonData.findSkin(baseSkin) as Skin;

        if (foundSkin) {
          skin.addSkin(foundSkin);
        }
      });

      return skin;
    }
  }

  applyCombinedSkins(skinName: string) {
    const skeleton = this.skeleton;
    const skin = this.createBaseCombinedSkin();

    if (!skeleton || !skin) {
      return;
    }

    const skeletonData = skeleton.data;

    skin.addSkin(skeletonData.findSkin(skinName) as Skin);

    skeleton.setSkin(skin);
    skeleton.setSlotsToSetupPose();
  }

  resetCombinedSkin() {
    const skeleton = this.skeleton;
    const skin = this.createBaseCombinedSkin();

    if (!skeleton || !skin) {
      return;
    }

    skeleton.setSkin(skin);
    skeleton.setSlotsToSetupPose();
  }

  changeAnimation(animationName: string, loop = true) {
    if (this.spinePlayer) {
      if (this.skeleton?.data.findAnimation(animationName)) {
        this.spinePlayer.setAnimation(animationName, loop);
      } else {
        console.error(`Animation "${animationName}" not found.`);
      }
    }
  }

  dispose() {
    if (this.spinePlayer) {
      this.spinePlayer.dispose();
      this.spinePlayer = null;
    }
  }
}
