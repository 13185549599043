import rankProgressFrame from '@media/league/rank-progress-frame-battle-result.svg';
import rank1 from '@media/league/rank-1.png';
import rank2 from '@media/league/rank-2.png';
import rank3 from '@media/league/rank-3.png';
import matchmakingForeground from '@media/matchmaking-foreground.png';
import defeatHero from '@media/battle-result/defeat-hero.png';
import defeatText from '@media/battle-result/defeat-text.png';
import escapedHero from '@media/battle-result/escaped-hero.png';
import escapedText from '@media/battle-result/escaped-text.png';
import tieHero from '@media/battle-result/tie-hero.png';
import tieText from '@media/battle-result/tie-text.png';
import victoryHero from '@media/battle-result/victory-hero.png';
import victoryText from '@media/battle-result/victory-text.png';

export const imagesToPreload = [
  rankProgressFrame,
  rank1,
  rank2,
  rank3,
  matchmakingForeground,
  defeatHero,
  defeatText,
  escapedHero,
  escapedText,
  tieHero,
  tieText,
  victoryHero,
  victoryText,
];
