import cn from 'classnames';

import { VersusSkillItemUpgrade } from '@app/types';
import { CoinsIndicator, VersusSkill } from '@components';
import { Button } from '@app/ui-kit';

import s from './VersusSkillsList.module.scss';

interface VersusSkillsListProps {
  balance: number;
  items: VersusSkillItemUpgrade[];
  className?: string;
}

export const VersusSkillsList: React.FC<VersusSkillsListProps> = ({
  balance,
  items,
  className,
}) => {
  const isUpgradeAvailable = (
    upgradePrice: number,
    skillLevel: number,
    maxLevel: number,
  ): { canUpgrade: boolean; msg: string } => {
    const isMaxLvl = skillLevel >= maxLevel;
    const canUpgrade = balance >= upgradePrice && !isMaxLvl;

    if (isMaxLvl) {
      return { canUpgrade, msg: 'Max level' };
    }

    if (canUpgrade) {
      return { canUpgrade, msg: `Upgrade to ${skillLevel + 1}` };
    }

    return { canUpgrade, msg: 'Not enough' };
  };

  const upgrade = async (canUpgrade: boolean, id: string) => {
    if (!canUpgrade) {
      return;
    }

    // TODO
    alert(`upgrade id: ${id}`);
  };

  return (
    <div className={cn(s.root, className)}>
      {items.map((el, i) => {
        const { id, upgradePrice, level, maxLevel } = el;
        const upgradeStatus = isUpgradeAvailable(upgradePrice, level, maxLevel);

        return (
          <div key={el.level + el.type + i} className={s.container}>
            <VersusSkill data={el} variant="skillsList" />
            {level < maxLevel && (
              <CoinsIndicator
                value={upgradePrice}
                animateOnChange={false}
                fontSize={20}
                variant="cucumber"
                className={s.value}
              />
            )}
            <Button
              variant="tertiary"
              size="medium"
              bgColor={
                upgradeStatus.canUpgrade
                  ? 'var(--action-primary-default)'
                  : 'var(--Action-Secondary-Base-Disabled)'
              }
              className={s.button}
              onClick={() => upgrade(upgradeStatus.canUpgrade, id)}
            >
              {upgradeStatus.msg}
            </Button>
          </div>
        );
      })}
    </div>
  );
};
