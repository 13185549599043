import { Injectable } from '@common/di';
import { MakeObservable, observable } from '@common/state';
import { ApiService } from '../ApiService';
import _debounce from 'lodash.debounce';

import { CacheService } from '../CacheService';

import { EventEmitter } from '../EventEmitter';
import type { RoundState } from '@app/types';

@Injectable()
@MakeObservable
export class VersusService extends EventEmitter {
  @observable
  public startRoundTime = 0;

  @observable
  public startRoundDuration = 0;

  @observable
  public currentRound = 0;

  @observable
  public hitForce = 0;

  @observable
  public hitForceMessage = '';

  @observable
  public isShowHitForce = false;

  @observable
  public isShowWaiting = false;

  @observable
  public isShowFight = false;

  @observable
  public isShowRound = false;

  @observable
  public isPreparationToDetect = false;

  @observable
  public detectForceTime = 0;

  @observable
  public isDetectForce = false;

  @observable
  isOpponentMoveCompleted = false;

  @observable
  direction = 1;

  @observable
  public roundState: RoundState = 'idle';

  constructor(
    private readonly apiService: ApiService,
    private readonly cacheService: CacheService,
  ) {
    super();
  }

  tick(delta: number) {
    this.detectForceTime += delta;

    this.emit('tick', this.detectForceTime);
  }

  nextState(state: VersusService['roundState']) {
    this.roundState = state;
    console.log('\nVersusService set next state: ', state);

    switch (state) {
      case 'startRound':
        this.isDetectForce = false;
        this.currentRound += 1;
        this.isShowRound = true;
        break;
      case 'preDetectForce':
        break;

      case 'detectForce':
        break;

      case 'showHitForce':
        break;

      case 'waiting':
        break;
      case 'fight':
        break;
      case 'idle':
        this.isDetectForce = false;
        this.currentRound = 0;
        break;
    }
  }

  async fetchStartRoundTime() {
    try {
      const response = await this.apiService.get<{ startTime: number }>(
        'game/start-time',
      );

      // Update the startRoundTime observable with the server value
      this.startRoundTime = response.startTime;
      this.emit('startRoundTimeUpdated', this.startRoundTime);
    } catch (e) {
      console.error('Failed to fetch start round time', e);
    }
  }

  async sendTimeToServer(time: number) {
    try {
      this.isDetectForce = true;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response = await this.apiService.post<any>(`game/`, {
        time: time,
      });

      return;
    } catch (e) {
      console.log('Failed to send time to the server', e);
    } finally {
      this.detectForceTime = 0;
    }
  }
}
