import React, { useEffect, useRef, useCallback } from 'react';
import { clsx } from 'clsx';
import _throttle from 'lodash.throttle';
import { vibrate } from '@app/utils';
import { SpinePlayer } from '@esotericsoftware/spine-player';
import { versusService } from '@app/services';
import { useServiceState } from '@app/common/state';

import s from './WormSpine.module.scss';

interface WormSpineProps {
  className?: string;
}

export const WormSpine: React.FC<WormSpineProps> = ({ className }) => {
  const rootRef = useRef<HTMLDivElement>(null);
  const rectRef = useRef<DOMRect>();
  const playerContainerRef = useRef<HTMLDivElement | null>(null);
  const playerRef = useRef<SpinePlayer | null>(null);
  const { detectForceTime, roundState, isDetectForce } = useServiceState(
    versusService,
    ['detectForceTime', 'roundState', 'isDetectForce'],
  );

  useEffect(() => {
    if (playerContainerRef.current) {
      const playerConfig = {
        skeleton: '/spine/slap_2_worm.json',
        atlas: '/spine/slap_2_worm.atlas',
        preserveDrawingBuffer: false,
        animation: 'idle',
        animations: ['idle', 'hardSlap'],
        showControls: false,
        showLoading: false,
        alpha: true,
        backgroundColor: '#00000000',
        viewport: { x: -150, y: 200, width: 450, height: 900 },

        success: (player: SpinePlayer) => {
          console.log('Player loaded successfully!');
          playerRef.current = player;
          player.play();

          if (player.animationState) {
            player.animationState.addListener({
              complete: (entry) => {
                if (
                  (entry.animation && entry.animation.name === 'hardSlap') ||
                  (entry.animation && entry.animation.name === 'invitingAnim')
                ) {
                  // Вернуться к анимации 'idle'
                  player.animationState?.setAnimation(0, 'idle', true);
                }
              },
            });
          }
        },
        error: (player: SpinePlayer, msg: string) => {
          console.error('Player failed to load:', msg);
        },
      };

      const player = new SpinePlayer(playerContainerRef.current, playerConfig);

      return () => {
        player.pause();
        player.dispose();
      };
    }
  }, []);

  const changeAnimation = (newAnimation: string, timeScale: number) => {
    if (playerRef.current) {
      // Устанавливаем новую анимацию
      const trackEntry = playerRef.current.setAnimation(newAnimation, false);

      // Ускоряем анимацию в 2 раза
      if (trackEntry) {
        trackEntry.timeScale = timeScale;
      }
    }
  };

  useEffect(() => {
    const rect = rootRef.current?.getBoundingClientRect();

    console.log('rect: ', rect);

    if (rect) {
      rectRef.current = rect;
    }
  }, []);

  useEffect(() => {
    if (roundState === 'fight') {
      if (isDetectForce) {
        setTimeout(() => {
          changeAnimation('hardSlap', 1.5);
        }, 1000);
      } else {
        setTimeout(() => {
          changeAnimation('invitingAnim', 1.5);
        }, 1000);
      }
    }
  }, [isDetectForce, roundState]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleTap = useCallback(
    (event: React.TouchEvent<HTMLDivElement>) => {
      const rect = rectRef.current;

      window.Telegram?.WebApp.expand();

      vibrate();

      // changeAnimation('hardSlap');
      if (!isDetectForce) {
        versusService.sendTimeToServer(detectForceTime);
        versusService.nextState('showHitForce');
      }
    },
    [detectForceTime, isDetectForce],
  );

  return (
    <div className={clsx(s.root, className)} ref={rootRef}>
      <div className={s.tapArea} onTouchStart={handleTap}></div>
      <div className={s.worm} ref={playerContainerRef} />
    </div>
  );
};
