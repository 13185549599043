import React, { useState } from 'react';
import { AnimatePresence, m, Variants } from 'framer-motion';

import { BottomSheet } from '@app/ui-kit';
import { VersusLeagueRewardType } from '@app/types';
import { BottomButton } from '@components';
import { numberWithCommas } from '@app/utils';

import s from './ClaimLootBottomSheet.module.scss';

interface ClaimLootBottomSheetProps {
  isOpen: boolean;
  loot: VersusLeagueRewardType[];
  onClose: () => void;
}

export const ClaimLootBottomSheet: React.FC<ClaimLootBottomSheetProps> = ({
  isOpen,
  loot,
  onClose,
}) => {
  const [index, setIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldHide, setShouldHide] = useState(false);

  const currentItem = loot[index];

  const handleCollect = () => {
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setShouldHide(true);

      setTimeout(() => {
        const nextIndex = index + 1;

        if (loot[nextIndex]) {
          setIndex(nextIndex);
        } else {
          setIndex(0);
          setTimeout(() => {
            onClose();
          }, HIDE_TRANSITION_DURATION * 1000);
        }

        setShouldHide(false);
      }, HIDE_TRANSITION_DURATION * 1000);
    }, 1000);
  };

  return (
    <BottomSheet
      className={s.root}
      contentClassName={s.contentClassName}
      bgColor="var(--Surface-BG)"
      open={isOpen}
      withClose={false}
    >
      {!isOpen ? (
        <></>
      ) : (
        <div className={s.wrapper}>
          <AnimatePresence mode="wait">
            {!shouldHide && (
              <m.div
                key={currentItem.img}
                className={s.staggerContainer}
                initial="initial"
                animate={isLoading ? 'loading' : 'visible'}
                exit="hidden"
                variants={orchestrationConfig}
              >
                <m.img
                  src={currentItem.img}
                  alt="loot"
                  className={s.image}
                  variants={imageAnimation}
                />
                <m.div variants={textAnimation}>
                  <div className={s.title}>
                    {currentItem.value && numberWithCommas(currentItem.value)}{' '}
                    {currentItem.title}
                  </div>
                  <div className={s.description}>{currentItem.description}</div>
                </m.div>
              </m.div>
            )}
          </AnimatePresence>
          <BottomButton
            onClick={handleCollect}
            label={isLoading ? 'Loading...' : 'Collect'}
            disabled={isLoading} // Disable button during loading
          />
        </div>
      )}
    </BottomSheet>
  );
};
const HIDE_TRANSITION_DURATION = 0.5;

const hideTransition = {
  transition: { duration: HIDE_TRANSITION_DURATION },
};

const orchestrationConfig: Variants = {
  visible: {
    transition: {
      staggerChildren: 0.25,
      delayChildren: 0.5,
    },
  },
  hidden: {
    transition: {
      staggerDirection: -1,
      staggerChildren: 0.3,
    },
  },
};

const imageAnimation: Variants = {
  initial: { opacity: 0, scale: 0.5, y: -350 },
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 120, damping: 12 },
  },
  hidden: {
    opacity: 0,
    scale: 0,
    y: '50vh',
    ...{ hideTransition },
  },
  loading: {
    y: 0,
    opacity: 1,
    scale: [1, 0.7, 1],
    transition: {
      duration: 0.5,
      type: 'tween',
      repeat: Infinity,
      repeatType: 'loop',
    },
  },
};

const textAnimation: Variants = {
  initial: { opacity: 0, y: 100 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.3, ease: 'easeInOut' },
  },
  hidden: {
    opacity: 0,
    y: 0,
    ...{ hideTransition },
  },
  loading: { opacity: 1, y: 0 },
};
