import cn from 'classnames';

import { RankProgressBar, RankBadge } from '@components';

import s from './RankWithProgress.module.scss';

interface RankWithProgressProps {
  level: number;
  currentProgress: number;
  showProgressValues: boolean;
  variant: 'menu' | 'battleResult' | 'leagueProgress';
  showBar?: boolean;
  min?: number;
  max?: number;
  className?: string;
  onClick?: () => void;
}

export const RankWithProgress: React.FC<RankWithProgressProps> = ({
  level,
  currentProgress,
  showProgressValues,
  variant,
  min = 0,
  max = 7,
  showBar = true,
  className,
  onClick,
}) => {
  return (
    <button className={cn(s.root, s[variant], className)} onClick={onClick}>
      {showBar && (
        <RankProgressBar
          currentProgress={currentProgress}
          showValues={showProgressValues}
          variant={variant}
          min={min}
          max={max}
          className={s.bar}
        />
      )}
      <RankBadge level={level} className={s.badge} />
    </button>
  );
};
