import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  BackButton,
  LeagueChart,
  LeagueRankName,
  Page,
  ProgressionRewards,
  RankBadge,
} from '@app/components';
import { Button } from '@app/ui-kit';
import { VersusLeagueRewards } from '@app/types';

import s from './LeagueProgressionPage.module.scss';

const TRANSITION_DURATION_S = 0.6;

export const LeagueProgressionPage: React.FC = () => {
  const navigate = useNavigate();
  const userRankLevel = 3;
  const userLeagueIndex = leaguesData
    .slice()
    .reverse()
    .findIndex((el) => el.rank <= userRankLevel);

  const correctLeagueIndex =
    userLeagueIndex !== -1 ? leaguesData.length - 1 - userLeagueIndex : 0;

  const [[currentSelectedLeagueIndex, direction], setSelectedRank] = useState([
    correctLeagueIndex,
    1,
  ]);

  const paginate = (newDirection: number) => {
    const newPageNum = currentSelectedLeagueIndex + newDirection;

    if (newPageNum < 0 || newPageNum >= leaguesData.length) {
      return;
    }

    setSelectedRank([newPageNum, newDirection]);
  };

  return (
    <Page isHaveBottomNav={false} className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <RankBadge
        level={userRankLevel}
        className={s.rankBadge}
        transitionDurationSeconds={TRANSITION_DURATION_S}
      />
      <div className={s.wrapper}>
        <LeagueRankName
          rankName={leaguesData[correctLeagueIndex]?.name}
          transitionDurationSeconds={TRANSITION_DURATION_S}
          navDirection={direction}
        />
        <Button
          btnType="link"
          variant="unfilled"
          size="large"
          gap="4px"
          onClick={() => {
            navigate('/league-leaderboard');
          }}
        >
          See leaderboard
        </Button>
      </div>
      <LeagueChart
        userRank={userRankLevel}
        selectedRankIndex={currentSelectedLeagueIndex}
        leagueData={leaguesData}
        transitionDurationSeconds={TRANSITION_DURATION_S}
        className={s.chart}
      />
      <ProgressionRewards
        userRank={userRankLevel}
        selectedRankIndex={currentSelectedLeagueIndex}
        leagueData={leaguesData}
        direction={direction}
        transitionDurationSeconds={TRANSITION_DURATION_S}
        className={s.rewards}
        onChange={paginate}
      />
    </Page>
  );
};

const leaguesData: VersusLeagueRewards[] = [
  {
    rank: 0,
    name: 'Microbe',
    rewardClaimed: false,
    rewards: [
      {
        img: 'https://i.ibb.co/0rhBxSp/image-44343.png',
        value: 400000,
        title: 'Cucumbers',
        description: 'Level up your skill using cucumbers',
      },
      {
        img: 'https://i.ibb.co/4F9QWdw/image-341.png',
        title: 'Focus Pokus',
        description: 'Slap the hell out of your opponents in style',
      },
      {
        img: 'https://i.ibb.co/7W1qYvX/image-343.png',
        title: 'Cucumber Googles',
        description: 'Googles description',
      },
    ],
  },
  {
    rank: 1,
    name: 'Fish Bait',
    rewardClaimed: true,
    rewards: [
      {
        img: 'https://i.ibb.co/ZL2rBz5/image1341.png',
        title: 'Metal Googles',
        description: 'Googles description',
      },
      {
        img: 'https://i.ibb.co/0rhBxSp/image-44343.png',
        value: 50050,
        title: 'Cucumbers',
        description: 'Level up your skill using cucumbers',
      },
      {
        img: 'https://i.ibb.co/NtwmyCR/image-123341.png',
        title: 'Tickets',
        description: 'Enter Slap Tournament and win 50 TON',
      },
    ],
  },
  {
    rank: 5,
    name: 'Crow Snack',
    rewardClaimed: false,
    rewards: [
      {
        img: 'https://i.ibb.co/3v5wNVp/image-342.png',
        title: 'Item',
        description: 'Item description',
      },
      {
        img: 'https://i.ibb.co/4F9QWdw/image-341.png',
        title: 'Focus Pokus',
        description: 'Slap the hell out of your opponents in style',
      },
      {
        img: 'https://i.ibb.co/7W1qYvX/image-343.png',
        title: 'Cucumber Googles',
        description: 'Googles description',
      },
    ],
  },

  {
    rank: 10,
    name: 'Rat Feast',
    rewardClaimed: false,
    rewards: [
      {
        img: 'https://i.ibb.co/3v5wNVp/image-342.png',
        title: 'Item',
        description: 'Item description',
      },
      {
        img: 'https://i.ibb.co/4F9QWdw/image-341.png',
        title: 'Hat',
        description: 'Hat description',
      },
      {
        img: 'https://i.ibb.co/7W1qYvX/image-343.png',
        title: 'Cucumber Googles',
        description: 'Googles description',
      },
    ],
  },
  {
    rank: 20,
    name: 'Fox Meal',
    rewardClaimed: false,
    rewards: [
      {
        img: 'https://i.ibb.co/3v5wNVp/image-342.png',
        title: 'Item',
        description: 'Item description',
      },
      {
        img: 'https://i.ibb.co/4F9QWdw/image-341.png',
        title: 'Hat',
        description: 'Hat description',
      },
      {
        img: 'https://i.ibb.co/7W1qYvX/image-343.png',
        title: 'Cucumber Googles',
        description: 'Googles description',
      },
    ],
  },
  {
    rank: 40,
    name: 'Bear Delight',
    rewardClaimed: false,
    rewards: [
      {
        img: 'https://i.ibb.co/3v5wNVp/image-342.png',
        title: 'Item',
        description: 'Item description',
      },
      {
        img: 'https://i.ibb.co/4F9QWdw/image-341.png',
        title: 'Hat',
        description: 'Hat description',
      },
      {
        img: 'https://i.ibb.co/7W1qYvX/image-343.png',
        title: 'Cucumber Googles',
        description: 'Googles description',
      },
    ],
  },
  {
    rank: 50,
    name: 'Whale Breakfast',
    rewardClaimed: false,
    rewards: [
      {
        img: 'https://i.ibb.co/3v5wNVp/image-342.png',
        title: 'Item',
        description: 'Item description',
      },
      {
        img: 'https://i.ibb.co/4F9QWdw/image-341.png',
        title: 'Hat',
        description: 'Hat description',
      },
      {
        img: 'https://i.ibb.co/7W1qYvX/image-343.png',
        title: 'Cucumber Googles',
        description: 'Googles description',
      },
    ],
  },
];
