import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';

import { ReactComponent as UrlAlertIconWarning } from '@icons/warning.svg';

import s from './ImageLoader.module.scss';

interface ImageLoaderProps {
  src: string;
  alt: string;
  className?: string;
}

export const ImageLoader: React.FC<ImageLoaderProps> = ({
  src,
  alt,
  className,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const imgRef = useRef<HTMLImageElement | null>(null);

  const handleImageLoad = () => {
    setLoading(false);
  };

  const handleImageError = () => {
    setError(true);
    setLoading(false);
  };

  useEffect(() => {
    const img = imgRef.current;

    if (!img?.complete) {
      setLoading(true);
    }
  }, [src]);

  return (
    <div className={cn(s.root, className)}>
      {loading && !error && <Spinner />}
      {!error && (
        <img
          ref={imgRef}
          src={src}
          alt={alt}
          onLoad={handleImageLoad}
          onError={handleImageError}
          className={cn(s.image, { [s.visible]: !loading })}
        />
      )}
      {error && <UrlAlertIconWarning color="red" className={s.errorIcon} />}
    </div>
  );
};

const Spinner = () => (
  <div className={s.spinnerContainer}>
    <div className={s.spinner} />
  </div>
);
