export enum VersusSkillType {
  power,
  reaction,
  resilience,
}

export interface VersusSkillItem {
  id: string;
  type: VersusSkillType;
  level: number;
}

export interface VersusSkillItemUpgrade extends VersusSkillItem {
  upgradePrice: number;
  maxLevel: number;
}

export enum VersusBattleResultType {
  victory,
  defeat,
  tie,
  escaped,
}

export interface VersusRewardType {
  multiplier?: number;
  name: string;
  points: number;
}

export type RoundState =
  | 'idle'
  | 'startRound'
  | 'preDetectForce'
  | 'detectForce'
  | 'showHitForce'
  | 'waiting'
  | 'fight';

export interface VersusWheelState {
  state: RoundState;
  sectorColor?: string;
  text?: string;
  textColor?: string;
  sectorsImg?: string;
}

export interface stateIndicator {
  sectorColor?: string;
  text?: string;
  textColor?: string;
}

export interface VersusLeagueRewardType {
  img: string;
  value?: number;
  title: string;
  description: string;
}

export interface VersusLeagueRewards {
  rank: number;
  name: string;
  rewardClaimed: boolean;
  rewards: VersusLeagueRewardType[];
}

export interface LeaderboardEntry {
  id: number;
  place: number;
  avatar: string;
  name: string;
  rating: number;
}

export interface LeagueLeaderboardData {
  rank: number;
  name: string;
  leaderboard: LeaderboardEntry[];
}
