import cn from 'classnames';

import iconPower from '@media/skills/power.png';
import iconReaction from '@media/skills/reaction.png';
import iconResilience from '@media/skills/resilience.png';
import skillFrame from '@media/skills/skill-frame.png';
import levelFrameStatBar from '@media/skills/skill-level-frame-stat-bar.png';
import levelFrameSkillsList from '@media/skills/skill-level-frame-skills-list.png';

import { VersusSkillItem, VersusSkillType } from '@app/types';

import s from './VersusSkill.module.scss';

interface VersusSkillProps {
  variant: 'statBar' | 'skillsList';
  data: VersusSkillItem;
  className?: string;
}

export const VersusSkill: React.FC<VersusSkillProps> = ({
  variant,
  data,
  className,
}) => {
  const { type, level } = data;
  const { icon, backgroundColor } = skillIcons[type];

  return (
    <div className={cn(s.root, s[variant], className)}>
      <div className={s.skillBg} style={{ backgroundColor }} />

      <img src={skillFrame} alt={`skill frame`} className={s.skillFrame} />
      <img src={icon} alt={type.toString()} className={s.skillIcon} />
      <div className={s.levelWrapper}>
        <img
          src={skillLevelFrames[variant]}
          alt={`skill level: ${level}`}
          className={s.levelFrame}
        />
        <div className={s.levelValue}>{level}</div>
      </div>
    </div>
  );
};

const skillIcons: Record<
  VersusSkillType,
  { icon: string; backgroundColor: string }
> = {
  [VersusSkillType.power]: { icon: iconPower, backgroundColor: '#D44F41' },
  [VersusSkillType.reaction]: {
    icon: iconReaction,
    backgroundColor: '#5B7BCC',
  },
  [VersusSkillType.resilience]: {
    icon: iconResilience,
    backgroundColor: '#AA68FF',
  },
};

const skillLevelFrames = {
  statBar: levelFrameStatBar,
  skillsList: levelFrameSkillsList,
};
