import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { MatchMakingStatus, Page, VersusPlayerBars } from '@components';
import { BackButton } from '@components';
import foregroundImg from '@media/matchmaking-foreground.png';
import playerImg from '@media/matchmaking-player.png';
import opponentImg from '@media/matchmaking-opponent.png';
import { VersusSkillItem, VersusSkillType } from '@app/types';

import s from './MatchMakingPage.module.scss';

export const MatchMakingPage = () => {
  const navigate = useNavigate();
  const [isLooking, setIsLooking] = useState(true);

  const playerSkills: VersusSkillItem[] = [
    { id: '1', type: VersusSkillType.power, level: 5 },
    { id: '2', type: VersusSkillType.reaction, level: 15 },
    { id: '3', type: VersusSkillType.resilience, level: 95 },
  ];

  const opponentSkills: VersusSkillItem[] = [
    { id: '1', type: VersusSkillType.power, level: 15 },
    { id: '2', type: VersusSkillType.reaction, level: 5 },
    { id: '3', type: VersusSkillType.resilience, level: 65 },
  ];

  useEffect(() => {
    setTimeout(() => setIsLooking(false), 4000);
  });

  useEffect(() => {
    if (!isLooking) {
      setTimeout(() => {
        navigate('/battle');
      }, 3000);
    }
  });

  return (
    <Page className={s.root} isHaveBottomNav={false}>
      <BackButton onClick={() => navigate(-1)} />
      <VersusPlayerBars
        ownName={'subarumax'}
        opponentName={'jefferson'}
        ownSkills={playerSkills}
        opponentSkills={isLooking ? null : opponentSkills}
        className={s.playersBar}
      />
      <motion.div
        initial={{ x: -150, y: 50, opacity: 0 }}
        animate={{ x: 0, y: 0, opacity: 1 }}
        transition={{
          type: 'tween',
          duration: 0.5,
        }}
        className={s.wrapper}
      >
        <img src={playerImg} className={s.player} alt="player" />
      </motion.div>
      {!isLooking && (
        <motion.div
          initial={{ y: 300, x: 150 }}
          animate={{ y: 0, x: 0 }}
          transition={{
            type: 'tween',
            duration: 0.5,
          }}
          className={s.wrapper}
        >
          <img src={opponentImg} className={s.opponent} alt="opponent" />
        </motion.div>
      )}
      <motion.img
        initial={{ scale: 1.5 }}
        animate={{ scale: 1 }}
        transition={{
          type: 'tween',
          duration: 0.5,
        }}
        src={foregroundImg}
        width={'100%'}
        height={'100%'}
        className={s.image}
        alt="ground"
      />
      <MatchMakingStatus
        mode={isLooking ? 'looking' : 'found'}
        className={s.status}
      />
    </Page>
  );
};
