import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

import { BackButton, LeaderboardRank, LeaderBoardTable } from '@app/components';
import { LeagueLeaderboardData } from '@app/types';

import s from './LeagueLeaderboardPage.module.scss';

const TRANSITION_DURATION = 0.6;

export const LeagueLeaderboardPage: React.FC = () => {
  const navigate = useNavigate();
  const userRankLevel = 3;
  const currentUserProgress = 5;
  const userLeagueIndex = leaguesData
    .slice()
    .reverse()
    .findIndex((el) => el.rank <= userRankLevel);

  const correctLeagueIndex =
    userLeagueIndex !== -1 ? leaguesData.length - 1 - userLeagueIndex : 0;

  const [[currentSelectedLeagueIndex, direction], setSelectedRank] = useState([
    correctLeagueIndex,
    1,
  ]);
  const isCurrentRank = currentSelectedLeagueIndex === correctLeagueIndex;

  const rankToRender =
    currentSelectedLeagueIndex !== correctLeagueIndex
      ? leaguesData[currentSelectedLeagueIndex].rank
      : userRankLevel;

  const rankName = leaguesData[currentSelectedLeagueIndex]?.name;

  const paginate = (newDirection: number) => {
    const newPageNum = currentSelectedLeagueIndex + newDirection;

    if (newPageNum < 0 || newPageNum >= leaguesData.length) {
      return;
    }

    setSelectedRank([newPageNum, newDirection]);
  };

  return (
    <div className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <LeaderboardRank
        currentSelectedLeagueIndex={currentSelectedLeagueIndex}
        leaguesAmount={leaguesData.length}
        rankNumber={rankToRender}
        rankName={rankName}
        isCurrentRank={isCurrentRank}
        currentUserProgress={currentUserProgress}
        navDirection={direction}
        transitionDurationSeconds={TRANSITION_DURATION}
        onChange={paginate}
      />
      <div className={s.table}>
        <LeaderBoardTable
          selectedLeagueIndex={currentSelectedLeagueIndex}
          isUserRank={isCurrentRank}
          data={leaguesData}
          navDirection={direction}
          transitionDurationSeconds={TRANSITION_DURATION}
          onChange={paginate}
        />
      </div>
    </div>
  );
};

const leaguesData: LeagueLeaderboardData[] = [
  {
    rank: 0,
    name: 'Microbe',
    leaderboard: [
      {
        id: 1,
        place: 1,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'hello_world',
        rating: 4400,
      },
      {
        id: 2,
        place: 2,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'somename',
        rating: 4200,
      },
      {
        id: 3,
        place: 3,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'veryloooongsomename',
        rating: 3800,
      },
      {
        id: 4,
        place: 4,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'xxx',
        rating: 2100,
      },
      {
        id: 5,
        place: 5,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: '',
        rating: 577,
      },
    ],
  },
  {
    rank: 1,
    name: 'Fish Bait',
    leaderboard: [
      {
        id: 6,
        place: 1,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'James',
        rating: 4400,
      },
      {
        id: 7,
        place: 2,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'John',
        rating: 4200,
      },
      {
        id: 8,
        place: 3,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Robert',
        rating: 3800,
      },
      {
        id: 9,
        place: 4,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Michael',
        rating: 2100,
      },
      {
        id: 10,
        place: 5,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'William',
        rating: 577,
      },
    ],
  },
  {
    rank: 5,
    name: 'Crow Snack',
    leaderboard: [
      {
        id: 11,
        place: 1,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'David',
        rating: 4400,
      },
      {
        id: 12,
        place: 2,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Richard',
        rating: 4200,
      },
      {
        id: 13,
        place: 3,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Joseph',
        rating: 3800,
      },
      {
        id: 14,
        place: 4,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Charles',
        rating: 2100,
      },
      {
        id: 15,
        place: 5,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Thomas',
        rating: 577,
      },
    ],
  },

  {
    rank: 10,
    name: 'Rat Feast',
    leaderboard: [
      {
        id: 16,
        place: 1,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'James',
        rating: 4400,
      },
      {
        id: 17,
        place: 2,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'John',
        rating: 4200,
      },
      {
        id: 18,
        place: 3,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Robert',
        rating: 3800,
      },
      {
        id: 19,
        place: 4,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Michael',
        rating: 2100,
      },
      {
        id: 20,
        place: 5,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'William',
        rating: 577,
      },
    ],
  },
  {
    rank: 20,
    name: 'Fox Meal',
    leaderboard: [
      {
        id: 21,
        place: 1,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Christopher',
        rating: 4400,
      },
      {
        id: 22,
        place: 2,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Daniel',
        rating: 4200,
      },
      {
        id: 23,
        place: 3,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Matthew',
        rating: 3800,
      },
      {
        id: 24,
        place: 4,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Anthony',
        rating: 2100,
      },
      {
        id: 25,
        place: 5,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'William',
        rating: 577,
      },
    ],
  },
  {
    rank: 40,
    name: 'Bear Delight',
    leaderboard: [
      {
        id: 26,
        place: 1,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'David',
        rating: 4400,
      },
      {
        id: 27,
        place: 2,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Richard',
        rating: 4200,
      },
      {
        id: 28,
        place: 3,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Joseph',
        rating: 3800,
      },
      {
        id: 29,
        place: 4,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Charles',
        rating: 2100,
      },
      {
        id: 30,
        place: 5,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Thomas',
        rating: 577,
      },
    ],
  },
  {
    rank: 50,
    name: 'Whale Breakfast',
    leaderboard: [
      {
        id: 31,
        place: 1,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Paul',
        rating: 4400,
      },
      {
        id: 32,
        place: 2,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Steven',
        rating: 4200,
      },
      {
        id: 33,
        place: 3,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Andrew',
        rating: 3800,
      },
      {
        id: 34,
        place: 4,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Kevin',
        rating: 2100,
      },
      {
        id: 35,
        place: 5,
        avatar: 'https://i.ibb.co/LRh3hSL/avatar.png',
        name: 'Brian',
        rating: 577,
      },
    ],
  },
];
