import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import {
  GamePage,
  BoostersPage,
  LeaguePage,
  GeneralLayout,
  SettingsPage,
  TomatoHowItWorkPage,
  TomatoQuestsPage,
  FriendsPage,
  ShopPage,
  SlapVersusPage,
  MatchMakingPage,
  SlapVersusComingPage,
  VersusBattleResultPage,
  SkillsPage,
  LeagueProgressionPage,
  LeagueLeaderboardPage,
  EquipPage,
} from '@app/pages';
import { LazyMotion, domAnimation } from 'framer-motion';
import {
  apiService,
  configService,
  gameService,
  userService,
} from './services';
import {
  DebugInfoPopup,
  DesktopStubScreen,
  Preloader,
  AutoBotBottomSheet,
  TomatoRewardBottomSheet,
  Maintenance,
} from '@components';
import { isMobile } from '@app/utils';
import { useImagesPreload } from './hooks';
import { imagesToPreload } from './constants/imagesToPreload';
import * as Sentry from '@sentry/react';
import { useAuth } from './hooks/useAuth';
import { useOnCloseApp } from './hooks/useOnCloseApp';
import { useServiceState } from './common/state';
import { StartParams, parseStartParams } from './utils/parseStartParams';
import { isAxiosError } from 'axios';
import { notifySuccess, notifyError } from '@ui-kit/ToastNotifications';
import { VersusBattlePage } from './pages/versus-battle';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
const startParams = parseStartParams<StartParams>();

const refUserId = parseInt(startParams?.ref || '');
const walletAddress = startParams?.address || '';
const walletAddressHash = startParams?.hash || '';

const App: React.FC = () => {
  const { isLoading } = useAuth(refUserId);
  const location = useLocation();
  const wormSkinsPreloaded = useImagesPreload();

  const { maintainSession } = useServiceState(apiService);

  const handleError = (
    error: unknown,
    componentStack?: string,
    eventId?: string,
  ) => {
    if (error instanceof Error) {
      if (error.message.includes('401')) {
        console.warn('Unauthorized access - 401 error');
      } else {
        notifyError(
          `Something went wrong!\n${error.message}\nPlease, try to reload the page.`,
          { autoClose: 5000 },
        );
      }
    } else {
      console.error('An unknown error occurred:', error);
    }
  };

  useEffect(() => {
    if (walletAddress && walletAddressHash) {
      userService
        .connectWallet(walletAddress, walletAddressHash)
        .then(() => {
          notifySuccess('Wallet connected successfully!');
        })
        .catch((error) => {
          if (isAxiosError(error)) {
            notifyError(error.response?.data.message || error.message, {
              autoClose: 5000,
            });
          } else {
            notifyError(
              `Something went wrong!\n${error.message}\nPlease, try to reload the page.`,
              { autoClose: 5000 },
            );
          }
        });
    }
  }, []);

  const closeApp = useOnCloseApp(async () => {
    return gameService.saveScore();
  });

  useEffect(() => {
    userService.authSteack();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      window.Telegram?.WebApp.expand();
    }, 0);
  }, []);

  if (!isMobile() && !configService.isDev) {
    return <DesktopStubScreen />;
  }

  if ((isLoading || !wormSkinsPreloaded) && !maintainSession) {
    return <Preloader />;
  }

  if (maintainSession) {
    return <Maintenance timestamp={maintainSession} />;
  }

  return (
    <LazyMotion features={domAnimation}>
      <TomatoRewardBottomSheet />
      <DebugInfoPopup />
      <AutoBotBottomSheet />

      <AnimatePresence mode="wait">
        <Sentry.ErrorBoundary onError={handleError}>
          <SentryRoutes location={location} key={location.pathname}>
            <Route path="/" element={<GamePage onClose={closeApp} />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/league" element={<LeaguePage />} />
            <Route path="/battle" element={<VersusBattlePage />} />

            <Route
              path="/play"
              element={
                configService.env === 'production' ? (
                  <SlapVersusComingPage />
                ) : (
                  <SlapVersusPage />
                )
              }
            />
            <Route path="/match-making" element={<MatchMakingPage />} />
            <Route path="/battle-result" element={<VersusBattleResultPage />} />
            <Route path="/skills/:mode" element={<SkillsPage />} />
            <Route
              path="/league-progression"
              element={<LeagueProgressionPage />}
            />
            <Route
              path="/league-leaderboard"
              element={<LeagueLeaderboardPage />}
            />
            <Route path="/equip" element={<EquipPage />} />
            <Route element={<GeneralLayout />}>
              <Route
                path="/tomato-how-it-work"
                element={<TomatoHowItWorkPage />}
              />
              <Route path="/shop" element={<ShopPage />} />
              <Route path="/friends" element={<FriendsPage />} />
              <Route path="/quests" element={<TomatoQuestsPage />} />
              <Route path="/boosters" element={<BoostersPage />} />
              <Route path="*" element={<GamePage onClose={closeApp} />} />
            </Route>
          </SentryRoutes>
        </Sentry.ErrorBoundary>
      </AnimatePresence>
    </LazyMotion>
  );
};

export default App;
