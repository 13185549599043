import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import {
  BackButton,
  BottomButton,
  CucumberBalance,
  Page,
  VersusRewardsPopUps,
  VersusSkillsList,
} from '@app/components';
import {
  VersusRewardType,
  VersusSkillItemUpgrade,
  VersusSkillType,
} from '@app/types';

import s from './SkillsPage.module.scss';

export const SkillsPage: React.FC = () => {
  const { mode } = useParams<{ mode: 'default' | 'battle-result' }>();
  const navigate = useNavigate();
  const { cucumbers } = { cucumbers: 150050 };
  const [newBalance, setNewBalance] = useState(cucumbers);
  const isDefault = mode === 'default';
  const isBattleResultMode = mode === 'battle-result';

  useEffect(() => {
    if (isBattleResultMode && !rewards) {
      navigate('/play');
    }
  }, [isBattleResultMode, navigate]);

  const updateBalance = (index: number) => {
    if (!rewards) {
      return;
    }

    setNewBalance((prev) => prev + rewards[index].points);
  };

  return (
    <Page isHaveBottomNav={false} className={s.root}>
      {isDefault && <BackButton onClick={() => navigate(-1)} />}
      <VersusRewardsPopUps
        data={isBattleResultMode ? rewards : null}
        onStartAnimation={(i) => updateBalance(i)}
      />
      <CucumberBalance
        balance={newBalance}
        animate={cucumbers !== newBalance}
        animateDuration={isDefault ? 0.5 : 1.5}
        className={s.balance}
      />
      <VersusSkillsList
        balance={cucumbers}
        items={skillsData}
        className={s.skillsList}
      />
      {isBattleResultMode && (
        <BottomButton label="Next" onClick={() => navigate('/play')} />
      )}
    </Page>
  );
};

const skillsData: VersusSkillItemUpgrade[] = [
  {
    id: '1',
    type: VersusSkillType.power,
    level: 1,
    upgradePrice: 15000,
    maxLevel: 99,
  },
  {
    id: '2',
    type: VersusSkillType.reaction,
    level: 25,
    upgradePrice: 205129,
    maxLevel: 99,
  },
  {
    id: '3',
    type: VersusSkillType.resilience,
    level: 99,
    upgradePrice: 66900,
    maxLevel: 99,
  },
];

const rewards: VersusRewardType[] | null = [
  { name: 'Victory', points: 1000 },
  { name: 'Splendid slap', points: 400, multiplier: 4 },
  { name: 'Bonus', points: 500, multiplier: 2 },
];
