import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import s from './StateIndicator.module.scss';
import { transform } from 'framer-motion';

interface StateIndicatorProps {
  className?: string;
  nextState?: {
    sectorColor?: string;
    text?: string;
    textColor?: string;
  };
  isAnimate?: boolean;
  animationDir?: 1 | -1;
  onAnimationEnd?: () => void;
}

export const StateIndicator: React.FC<StateIndicatorProps> = ({
  className,
  nextState,
  isAnimate = false,
  animationDir = 1,
  onAnimationEnd,
}) => {
  const imgRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const imgElement = imgRef.current;

    if (!imgElement) return;

    const angle = isAnimate ? 0 : 180 * animationDir;

    imgElement.style.transform = `translate(-50%, -50%) rotate(${angle}deg)`;

    // Обработчик завершения анимации
    const handleTransitionEnd = () => {
      onAnimationEnd?.();
    };

    // Добавляем событие transitionend
    imgElement.addEventListener('transitionend', handleTransitionEnd, {
      once: true,
    });

    return () => {
      imgElement.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [animationDir, isAnimate, onAnimationEnd]);

  const defaultAngle = 180 * animationDir;

  return (
    <div
      ref={imgRef}
      className={clsx(s.root, className)}
      style={{
        transform: `translate(-50%, -50%) rotate(${defaultAngle}deg)`,
        transition: 'transform 1s cubic-bezier(0.68, -0.6, 0.32, 1.6)',
      }}
    >
      <svg viewBox="0 0 356 356" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M35.2428 71.6597L178 178L320.757 71.6597C288.304 28.1641 236.441 0 178 0C119.559 0 67.6955 28.164 35.2428 71.6597Z"
          fill={nextState?.sectorColor}
        />
        <defs>
          <path
            id="textPath"
            fill="none"
            d="M73.93,80.14c0,0,49.87-48.76,105.03-47C236.75,34.99,282,81.24,282,81.24"
          />
        </defs>
        <text className={s.text} fill={nextState?.textColor}>
          <textPath
            href="#textPath"
            startOffset="50%"
            textAnchor="middle"
            fontFamily="Troika"
            fontSize="40px"
            fill={nextState?.textColor}
          >
            {nextState?.text}
          </textPath>
        </text>
      </svg>
    </div>
  );
};
