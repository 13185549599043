import cn from 'classnames';
import { AnimatePresence, m } from 'framer-motion';

import { FramerDragDetector, LeaderBoardTableItem } from '@components';
import { useServiceState } from '@app/common/state';
import { userService } from '@app/services';
import { LeagueLeaderboardData } from '@app/types';

import s from './LeaderBoardTable.module.scss';

interface LeaderBoardTableProps {
  selectedLeagueIndex: number;
  data: LeagueLeaderboardData[];
  isUserRank: boolean;
  navDirection: number;
  transitionDurationSeconds?: number;
  className?: string;
  onChange: (direction: -1 | 1) => void;
}

export const LeaderBoardTable: React.FC<LeaderBoardTableProps> = ({
  selectedLeagueIndex,
  data,
  isUserRank,
  navDirection,
  transitionDurationSeconds = 0.5,
  className,
  onChange,
}) => {
  const { userProfile } = useServiceState(userService, ['userProfile']);
  const userId = userProfile?.id;
  const userName = userProfile?.username ?? '';
  // TODO
  const userPlace = 216;
  const userRating = 35;
  const userAvatar = 'https://i.ibb.co/LRh3hSL/avatar.png';

  const currentLeague = data[selectedLeagueIndex];

  const userInLeaderboard = currentLeague.leaderboard.find(
    (player) => player.id === userId,
  );

  const shouldRenderUserSeparate =
    isUserRank && !userInLeaderboard && userPlace >= 6;

  return (
    <AnimatePresence initial={false} custom={navDirection} mode="popLayout">
      <m.div
        custom={navDirection}
        variants={variants}
        initial="enter"
        animate="center"
        exit="exit"
        transition={{ duration: transitionDurationSeconds, type: 'tween' }}
        key={currentLeague.rank}
        className={cn(s.root, className)}
      >
        <FramerDragDetector onChange={onChange}>
          <div className={s.tableHead}>
            <div>Place</div>
            <div>Rating</div>
          </div>
          {shouldRenderUserSeparate && (
            <m.div className={s.separateSelfItem}>
              <LeaderBoardTableItem
                place={userPlace}
                avatar={userAvatar}
                name={userName}
                rating={userRating}
              />
            </m.div>
          )}
          <div className={s.tableBody}>
            {currentLeague.leaderboard.map((player) => (
              <LeaderBoardTableItem
                key={player.id}
                place={player.place}
                avatar={player.avatar}
                name={player.name}
                rating={player.rating}
              />
            ))}
          </div>
        </FramerDragDetector>
      </m.div>
    </AnimatePresence>
  );
};

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? '150vw' : '-150vw',
    };
  },
  center: {
    x: 0,
  },
  exit: (direction: number) => {
    return {
      x: direction < 0 ? '150vw' : '-150vw',
    };
  },
};
