import { imagesToPreload } from '@app/constants/imagesToPreload';
import { randomSlapSoundService } from '@app/services';
import { useEffect, useState } from 'react';

export const usePreloadResources = () => {
  const [loadedImages, setLoadedImages] = useState<string[]>([]);
  const [soundsLoaded, setSoundsLoaded] = useState(false);
  const imagesLoaded = loadedImages.length === imagesToPreload.length;
  const allResourcesLoaded = imagesLoaded && soundsLoaded;

  useEffect(() => {
    imagesToPreload.forEach((url) => {
      const img = new Image();

      img.src = url;
      img.onload = () => {
        setLoadedImages((prev) => [...prev, url]);
      };
      img.onerror = (e) => console.error(`Error loading: ${url}`);
    });

    randomSlapSoundService
      .loadSlapSounds()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setSoundsLoaded(true);
      });
  }, []);

  return allResourcesLoaded;
};
