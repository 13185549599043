import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';

import timerCover from '@media/versus/timer-cover.png';

import s from './Timer.module.scss';

interface TimerProps {
  className?: string;
  duration: number;
  isActive: boolean;
  isPaused?: boolean;
  onTimeEnd?: () => void;
}

export const Timer: React.FC<TimerProps> = ({
  className,
  duration,
  isActive,
  isPaused,
  onTimeEnd,
}) => {
  const [progress, setProgress] = useState(100);
  const [color, setColor] = useState('#ABED5E');
  const startTimeRef = useRef(0);
  const animationFrameRef = useRef<number | null>(null);

  useEffect(() => {
    if (isActive && !isPaused) {
      startTimeRef.current = performance.now();
      requestAnimationFrame(update);
    } else {
      setProgress(100);
      setColor('#ABED5E');

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    }

    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, isPaused]);

  const update = () => {
    const delta = performance.now() - startTimeRef.current;
    const difference = duration - delta;
    const percent = Math.max(0, Math.min(100, (difference / duration) * 100));

    setProgress(percent);

    if (percent > 50) {
      setColor('#ABED5E');
    } else if (percent > 25) {
      setColor('#FFC166');
    } else {
      setColor('#FF5C4F');
    }

    if (percent > 0) {
      animationFrameRef.current = requestAnimationFrame(update);
    } else {
      onTimeEnd?.();
    }
  };

  return (
    <div className={clsx(s.root, className)}>
      <div className={s.container}>
        <img className={s.cover} src={timerCover} alt="cover" />
        <div
          className={s.progress}
          style={
            {
              '--progress': progress,
              background: `conic-gradient(transparent calc(360deg - 3.6deg * var(--progress)), ${color} calc(360deg - 3.6deg * var(--progress)))`,
            } as React.CSSProperties
          }
        ></div>
      </div>
    </div>
  );
};
