import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import wheelCover from '@media/versus/wheel-cover.png';

import s from './Wheel.module.scss';
import { StateIndicator } from '../StateIndicator';
import { stateIndicator, RoundState, VersusWheelState } from '@app/types';
import { versusService } from '@app/services';

interface VersusWheelProps {
  className?: string;
  force?: stateIndicator;
  state: RoundState;
  currentRound: number;
  sectorsImg?: string;
}

export const Wheel: React.FC<VersusWheelProps> = ({
  className,
  force,
  currentRound,
  sectorsImg,
  state,
}) => {
  const [firstState, setFirstState] = useState<stateIndicator>();
  const [secondState, setSecondState] = useState<stateIndicator>();

  useEffect(() => {
    const firstConfig = firstStateConfig.find(
      (config) => config.stateKey === state,
    );
    const secondConfig = secondStateConfig.find(
      (config) => config.stateKey === state,
    );

    if (firstConfig) {
      setFirstState(firstConfig.nextState);
    }

    if (secondConfig) {
      setSecondState(secondConfig.nextState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, force, currentRound]);

  const handleNextState = (nextState: RoundState, delay: number) => {
    setTimeout(() => {
      console.log(`Wheel try to set state: ${nextState}`);
      versusService.nextState(nextState);
    }, delay);
  };

  const handleAnimationEnd = () => {
    switch (state) {
      case 'startRound':
        handleNextState('preDetectForce', 2000);
        break;
      case 'waiting':
        handleNextState('fight', 2000);
        break;
      case 'showHitForce':
        handleNextState('waiting', 2000);
        break;
      default:
        break;
    }
  };

  const firstStateConfig = [
    {
      stateKey: 'startRound',
      nextState: {
        sectorColor: '#26201C',
        text: `round ${currentRound}`,
        textColor: '#FFEBCC',
      },
    },
    {
      stateKey: 'waiting',
      nextState: {
        sectorColor: '#907968',
        text: 'waiting',
        textColor: '#000',
      },
    },
  ];

  const secondStateConfig = [
    {
      stateKey: 'showHitForce',
      nextState: force,
      animationDir: 1,
    },
    {
      stateKey: 'fight',
      nextState: {
        sectorColor: '#F85B4E',
        text: 'fight',
        textColor: '#FFEBCC',
      },
    },
  ];

  return (
    <div className={clsx(s.root, className)}>
      <StateIndicator
        className={s.stateIndicator}
        nextState={firstState}
        animationDir={-1}
        isAnimate={state === 'startRound' || state === 'waiting'}
        onAnimationEnd={handleAnimationEnd}
      />

      <StateIndicator
        className={s.stateIndicator}
        nextState={secondState}
        animationDir={1}
        isAnimate={state === 'showHitForce' || state === 'fight'}
        onAnimationEnd={handleAnimationEnd}
      />

      {sectorsImg ? (
        <img className={s.sectorDiagram} src={sectorsImg} alt="wheel" />
      ) : null}

      <img className={s.cover} src={wheelCover} alt="wheel cover" />
    </div>
  );
};
