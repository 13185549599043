import { useEffect, useRef, useState } from 'react';
import { animate } from 'framer-motion';
import cn from 'classnames';

import { VersusLeagueRewards } from '@app/types';
import { RankWithProgress } from '@components/RankWithProgress';

import s from './LeagueChart.module.scss';

interface LeagueChartProps {
  userRank: number;
  selectedRankIndex: number;
  leagueData: VersusLeagueRewards[];
  transitionDurationSeconds: number;
  className?: string;
}

export const LeagueChart: React.FC<LeagueChartProps> = ({
  userRank,
  leagueData,
  selectedRankIndex,
  transitionDurationSeconds,
  className,
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateScroll = () => {
    const target = document.querySelector(
      `[data-rank-index="${selectedRankIndex}"]`,
    ) as HTMLDivElement;

    if (target && containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const badgeCenter =
        target.offsetLeft - containerWidth / 2 + target.offsetWidth / 2;

      const currentScroll = containerRef.current.scrollLeft;

      animate(currentScroll, badgeCenter, {
        type: 'tween',
        duration: !isMounted ? 0 : transitionDurationSeconds,
        onUpdate: (latest) => {
          if (containerRef.current) {
            containerRef.current.scrollLeft = latest;
          }
        },
      });
    }
  };

  useEffect(() => {
    calculateScroll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, selectedRankIndex]);

  useEffect(() => {
    const timer = setTimeout(() => setIsMounted(true), 0);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      calculateScroll();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRankIndex, isMounted]);

  return (
    <div className={cn(s.root, className)}>
      <div ref={containerRef} className={s.badgesContainer}>
        {leagueData.map((el, index) => {
          const nextRank = leagueData[index + 1]?.rank ?? el.rank + 1;
          const isCurrentRank = el.rank <= userRank;

          const currentProgress = isCurrentRank
            ? Math.min(userRank - el.rank, nextRank - el.rank)
            : 0;
          const max = nextRank - el.rank;

          return (
            <div
              data-rank-index={index}
              key={el.rank}
              className={s.rankWrapper}
            >
              <RankWithProgress
                level={el.rank}
                currentProgress={currentProgress}
                min={0}
                max={max}
                showProgressValues={false}
                variant="leagueProgress"
                showBar={index !== leagueData.length - 1}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
