import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

import arrowImg from '@media/versus/hand-arrow.png';

import s from './Arrow.module.scss';
import { getAnimationProgress } from '@app/utils/getAnimationProgress';
import { versusService } from '@app/services';
import { easeOutBounce, easeOutQuad } from '@app/utils/easingFunctions';

interface ArrowProps {
  className?: string;
  speed: number;
  progress?: number;
  state: string;
  endAnimation?: boolean;
  isDetectForce?: boolean;
  onStartAnimationEnd?: () => void;
}

export const Arrow: React.FC<ArrowProps> = ({
  className,
  speed,
  progress,
  state,
  endAnimation = false,
  isDetectForce,
  onStartAnimationEnd,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const currentAngleRef = useRef(45);
  const period = 1000 / speed;

  useEffect(() => {
    if (state === 'preDetectForce') {
      let startTime: number | null = null;

      const initialAnimate = (timestamp: number) => {
        if (!startTime) startTime = timestamp;

        const elapsed = timestamp - startTime;
        const duration = 1000;
        const progress = easeOutBounce(Math.min(elapsed / duration, 1));
        const angle = -90 + progress * 135;

        if (imgRef.current) {
          imgRef.current.style.transform = `translate(-50%, -50%) rotate(${angle}deg)`;
        }

        if (progress < 1) {
          requestAnimationFrame(initialAnimate);
        } else {
          if (imgRef.current) {
            const transitionEndEvent = new Event('transitionend');

            imgRef.current.dispatchEvent(transitionEndEvent);
          }
        }
      };

      requestAnimationFrame(initialAnimate);

      const handleTransitionEnd = () => {
        console.log('Animation completed!');

        if (onStartAnimationEnd) {
          onStartAnimationEnd();
        }
      };

      const imgElement = imgRef.current;

      if (imgElement) {
        imgElement.addEventListener('transitionend', handleTransitionEnd);
      }

      return () => {
        if (imgElement) {
          imgElement.removeEventListener('transitionend', handleTransitionEnd);
        }
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (state === 'detectForce') {
      const animateTick = (detectForceTime: number) => {
        const currentProgress =
          typeof progress === 'number'
            ? progress
            : getAnimationProgress(detectForceTime, period);

        const angle = -45 + currentProgress * 90;

        currentAngleRef.current = angle;

        if (imgRef.current) {
          imgRef.current.style.transform = `translate(-50%, -50%) rotate(${angle}deg)`;
        }
      };

      versusService.on('tick', animateTick);

      return () => {
        versusService.off('tick', animateTick);
      };
    }
  }, [progress, period, state]);

  useEffect(() => {
    if (state === 'waiting') {
      let startTime: number | null = null;
      const startAngle = currentAngleRef.current;

      const endingAnimate = (timestamp: number) => {
        if (!startTime) startTime = timestamp;

        const elapsed = timestamp - startTime;
        const duration = 1000;
        const progress = easeOutQuad(Math.min(elapsed / duration, 1));
        const angle = startAngle - progress * (startAngle + 90);

        if (imgRef.current) {
          imgRef.current.style.transform = `translate(-50%, -50%) rotate(${angle}deg)`;
        }

        if (progress < 1) {
          requestAnimationFrame(endingAnimate);
        }
      };

      requestAnimationFrame(endingAnimate);
    }
  }, [state]);

  return (
    <img
      className={clsx(s.root, className)}
      src={arrowImg}
      alt=""
      ref={imgRef}
    />
  );
};
