import cn from 'classnames';

import { numberWithCommas } from '@app/utils';

import s from './LeaderBoardTableItem.module.scss';

interface LeaderBoardTableItemProps {
  place: number;
  avatar: string;
  name: string;
  rating: number;
  className?: string;
}

export const LeaderBoardTableItem: React.FC<LeaderBoardTableItemProps> = ({
  place,
  avatar,
  name,
  rating,
  className,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <div className={s.place}>{place > 100 ? '100+' : place}</div>
      <div className={s.avatarFrame}>
        <img src={avatar} alt="avatar" className={s.avatar} />
      </div>
      <div className={s.name}>@{name.length === 0 ? 'anonymous' : name}</div>
      <div className={s.rating}>{numberWithCommas(rating)}</div>
    </div>
  );
};
