import cn from 'classnames';
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react';

import { ReactComponent as CloseIcon } from '@icons/close.svg';
import cucumber from '@media/cucumber-icon.png';
import { VersusRewardType } from '@app/types';
import { TopUpAnimation } from '@components/TopUpAnimation';

import s from './VersusRewardsPopUps.module.scss';

interface VersusRewardsPopUpsProps {
  data: VersusRewardType[] | null;
  className?: string;
  onStartAnimation: (rewardIndex: number) => void;
}

const REWARD_DISPLAY_DURATION = 2.5;
const DELAY_BETWEEN_REWARDS = 0.5;
const POPUP_ANIMATION_DURATION = 0.6;
const POPUP_OFFSET_TOP = 16;

export const VersusRewardsPopUps: React.FC<VersusRewardsPopUpsProps> = ({
  data,
  className,
  onStartAnimation,
}) => {
  const [currentRewardIndex, setCurrentRewardIndex] = useState(0);
  const [isAnimatingOut, setIsAnimatingOut] = useState(false);

  useEffect(() => {
    if (data == null || currentRewardIndex >= data.length) {
      return;
    }

    const timer = setTimeout(() => {
      setIsAnimatingOut(true);
    }, REWARD_DISPLAY_DURATION * 1000);

    return () => clearTimeout(timer);
  }, [currentRewardIndex, data]);

  useEffect(() => {
    if (isAnimatingOut) {
      const slideOutTimer = setTimeout(() => {
        setCurrentRewardIndex((prev) => prev + 1);
        setIsAnimatingOut(false);
      }, DELAY_BETWEEN_REWARDS * 1000);

      return () => clearTimeout(slideOutTimer);
    }
  }, [isAnimatingOut]);

  if (data == null || data.length === 0 || currentRewardIndex >= data.length) {
    return null;
  }

  const reward = data[currentRewardIndex];
  const { multiplier, name, points } = reward;

  return (
    <div className={cn(s.root, className)}>
      <AnimatePresence>
        {!isAnimatingOut && (
          <motion.div
            key={name + points}
            className={s.reward}
            initial={{ opacity: 1, y: -200 }}
            animate={{ opacity: 1, y: POPUP_OFFSET_TOP }}
            exit={{ opacity: 1, y: -200 }}
            transition={{ duration: POPUP_ANIMATION_DURATION, type: 'tween' }}
            onUpdate={(latest) => {
              if (latest.y === POPUP_OFFSET_TOP) {
                onStartAnimation(currentRewardIndex);
              }
            }}
          >
            {multiplier && multiplier > 0 && (
              <div className={s.multiplier}>
                <CloseIcon className={s.multiplierIcon} />
                {multiplier}
              </div>
            )}
            <div className={s.rewardName}>{name}</div>
            <div className={s.points}>+{points}</div>
            <div className={s.imageWrapper}>
              <TopUpAnimation
                animateTo={'#cucumberBalanceAnimationTarget'}
                initialDelay={POPUP_ANIMATION_DURATION}
                initialScale={0.4}
                finalScale={0.9}
                elementsAmount={20}
                duration={POPUP_ANIMATION_DURATION}
                className={s.topUpAnimation}
                destinationYOffset={15}
              />
              <img src={cucumber} className={s.image} alt="points" />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
