import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';

import { Wheel } from './components/Wheel';
import { Arrow } from './components/Arrow';

import { versusService } from '@app/services';
import { useLoop } from '@app/hooks/useLoop';
import { RoundState } from '@app/types';
import { Timer } from './components';
import { useServiceState } from '@app/common/state';
import diagramImg from '@media/versus/diagram-img.png';

import s from './VersusBattleWheel.module.scss';

interface VersusBattleWheelProps {
  className?: string;
  state: RoundState;
}

export const VersusBattleWheel: React.FC<VersusBattleWheelProps> = ({
  className,
  state,
}) => {
  const { currentRound, isDetectForce } = useServiceState(versusService, [
    'currentRound',
    'isDetectForce',
  ]);

  const isDetectForceState = state === 'detectForce';

  useLoop((delta) => {
    versusService.tick(delta);
  }, isDetectForceState);

  const handleAnimationEnd = () => {
    setTimeout(() => {
      versusService.nextState('detectForce');
    }, 2000);
  };

  const handleEndTime = () => {
    if (!isDetectForce) {
      versusService.nextState('showHitForce');
    }
  };

  const force = useMemo(() => {
    if (!isDetectForce) {
      return {
        sectorColor: '#413730',
        text: `missed!`,
        textColor: '#C9B69C',
      };
    }

    return {
      sectorColor: '#B8FF66',
      text: `splendid!!!`,
      textColor: '#181411',
    };
  }, [isDetectForce]);

  return (
    <div className={clsx(s.root, className)}>
      <div className={s.container}>
        <Timer
          className={s.timer}
          duration={20000}
          isActive={isDetectForceState}
          onTimeEnd={handleEndTime}
          isPaused={isDetectForce}
        />
        <Wheel
          className={s.wheel}
          force={force}
          sectorsImg={diagramImg}
          currentRound={currentRound}
          state={state}
        />
        <Arrow
          className={s.arrow}
          speed={0.6}
          state={state}
          onStartAnimationEnd={handleAnimationEnd}
          isDetectForce={isDetectForceState}
        />
      </div>
    </div>
  );
};
