import { useNavigate } from 'react-router-dom';

import { BackButton, Page, Wormdrobe } from '@app/components';

import s from './EquipPage.module.scss';

export const EquipPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Page isHaveBottomNav={false} className={s.root}>
      <BackButton onClick={() => navigate(-1)} />
      <h2 className={s.title}>Wormdrobe</h2>
      <Wormdrobe />
    </Page>
  );
};
