import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import cn from 'classnames';

import { ProgressValues, VersusSkillItem } from '@app/types';
import { ProgressBar, VersusSkillsGroup } from '@components';
import { maskName } from '@app/utils';
import tomatoIcon from '@media/tomato_coin.png';

import s from './VersusPlayerBars.module.scss';

interface VersusPlayerBarsProps {
  ownName: string;
  opponentName: string | null;
  ownSkills: VersusSkillItem[];
  opponentSkills: VersusSkillItem[] | null;
  ownHp?: ProgressValues;
  opponentHp?: ProgressValues;
  score?: number | null;
  className?: string;
}

export const VersusPlayerBars: React.FC<VersusPlayerBarsProps> = ({
  ownName,
  opponentName,
  ownSkills,
  opponentSkills,
  ownHp,
  opponentHp,
  score,
  className,
}) => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const isOpponentAvailable = opponentSkills && opponentName;

  useEffect(() => {
    setShouldAnimate(!opponentSkills);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const opponentData = opponentSkills && (
    <>
      <div className={cn(s.name, s.opponent)}>{maskName(opponentName)}</div>
      <VersusSkillsGroup items={opponentSkills} />
      {opponentHp && (
        <ProgressBar
          fillBarColor={'var(--action-primary-default)'}
          barBgColor={'var(--Surface-Primary)'}
          variant="custom"
          minNumber={0}
          currentNumber={opponentHp.current}
          maxNumber={opponentHp.total}
          className={cn(s.hp, s.opponent)}
        />
      )}
    </>
  );

  return (
    <div className={cn(s.root, className)}>
      <div className={s.col}>
        <div className={s.name}>{maskName(ownName)}</div>
        <VersusSkillsGroup items={ownSkills} />
        {ownHp && (
          <ProgressBar
            fillBarColor={'var(--action-primary-default)'}
            barBgColor={'var(--Surface-Primary)'}
            variant="custom"
            minNumber={0}
            currentNumber={ownHp.current}
            maxNumber={ownHp.total}
            className={s.hp}
          />
        )}
      </div>
      {score !== null && score !== undefined && (
        <div className={s.col}>
          <img className={s.balanceIcon} src={tomatoIcon} alt="tomato" />
          <div className={s.balanceValue}>{score.toLocaleString('en-US')}</div>
        </div>
      )}
      {isOpponentAvailable && shouldAnimate && (
        <motion.div className={s.col} initial={{ y: -100 }} animate={{ y: 0 }}>
          {opponentData}
        </motion.div>
      )}
      {isOpponentAvailable && !shouldAnimate && (
        <div className={s.col}>{opponentData}</div>
      )}
    </div>
  );
};
