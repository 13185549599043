import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import iconReaction from '@app/assets/media/skills/reaction.png';
import { NavItem } from '@app/components/NavBars/NavItem';
import { Button } from '@app/ui-kit';
import { RankWithProgress } from '@components';

import s from './SlapVersusMenu.module.scss';

interface SlapVersusMenuProps {
  className?: string;
}

export const SlapVersusMenu: React.FC<SlapVersusMenuProps> = ({
  className,
}) => {
  const { level, progress } = { level: 1, progress: 2 };
  const navigate = useNavigate();

  return (
    <div className={cn(s.root, className)}>
      <NavItem
        label={'Skills'}
        icon={
          <div className={s.iconBg}>
            <img
              className={s.img}
              src={iconReaction}
              width={40}
              height={40}
              alt="skills"
            />
          </div>
        }
        onClick={() => {
          navigate('/skills/default');
        }}
      />
      <Button
        onClick={() => navigate('/match-making')}
        className={s.button}
        size="large"
      >
        Play
      </Button>
      <RankWithProgress
        variant="menu"
        level={level}
        currentProgress={progress}
        showProgressValues
        onClick={() => navigate('/league-progression')}
      />
    </div>
  );
};
