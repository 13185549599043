import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';

import { ReactComponent as DeselectIcon } from '@icons/deselect.svg';
import { SpineAnimation } from '@app/utils';
import { BottomButton, ImageLoader } from '@components';

import s from './Wormdrobe.module.scss';

interface WormdrobeProps {
  className?: string;
}

export const Wormdrobe: React.FC<WormdrobeProps> = ({ className }) => {
  const playerSkinName = 'accessories/hat-pointy-blue-yellow';
  const [selectedItem, setSelectedItem] = useState<string | null>(
    playerSkinName,
  );
  const [isSpineLoaded, setIsSpineLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const playerContainerRef = useRef<HTMLDivElement>(null);
  const spinePlayer = useRef<SpineAnimation | null>(null);

  useEffect(() => {
    spinePlayer.current = new SpineAnimation({
      playerContainer: playerContainerRef.current,
      config: animationConfig,
      playerSkinName: playerSkinName,
      baseSkins: baseSkins,
      onLoad: () => setIsSpineLoaded(true),
    });

    return () => {
      spinePlayer.current?.dispose();
    };
  }, []);

  const selectSkin = (skinName: string | null) => {
    if (skinName === selectedItem) {
      return;
    }

    if (skinName !== null) {
      spinePlayer.current?.applyCombinedSkins(skinName);
    } else {
      spinePlayer.current?.resetCombinedSkin();
    }

    setSelectedItem(skinName);
  };

  const confirmSelect = async () => {
    setIsLoading(true);

    // TODO call backend to apply accessory skin

    setTimeout(() => setIsLoading(false), 1000);
  };

  return (
    <div className={cn(s.root, className)}>
      <div
        className={cn(s.worm, { [s.visible]: isSpineLoaded })}
        ref={playerContainerRef}
      />
      <div className={s.wrapper}>
        <div className={s.itemsContainer}>
          <button
            className={cn(s.cell, s.deselect)}
            onClick={() => selectSkin(null)}
          >
            <DeselectIcon className={s.deselectIcon} />
          </button>
          {items.map((el) => (
            <button
              key={el.skinName}
              className={cn(s.cell, s.item, {
                [s.active]: selectedItem === el.skinName,
              })}
              onClick={() => selectSkin(el.skinName)}
            >
              <ImageLoader src={el.image} alt="item" className={s.itemImage} />
            </button>
          ))}
        </div>
        <BottomButton
          label={!isLoading ? 'Select' : 'Loading..'}
          disabled={isLoading}
          onClick={confirmSelect}
        />
      </div>
    </div>
  );
};

const animationConfig = {
  skeleton: '/spine/skin-test/mix-and-match-pro.json',
  atlas: '/spine/skin-test/mix-and-match-pma.atlas',
  preserveDrawingBuffer: false,
  animation: 'walk',
  showControls: false,
  showLoading: false,
  alpha: true,
  viewport: {
    x: -375,
    y: 0,
    width: 750,
    height: 750,
  },
};

const baseSkins = [
  'skin-base',
  'eyes/violet',
  'hair/brown',
  'clothes/hoodie-orange',
  'legs/pants-jeans',
  'eyelids/girly',
];

const items = [
  {
    image: 'https://i.ibb.co/KFTgfBJ/hat.png',
    skinName: 'accessories/hat-pointy-blue-yellow',
  },
  {
    image: 'https://i.ibb.co/8cSM1Fs/cucumbergoogles.png',
    skinName: 'accessories/backpack',
  },
  {
    image: 'https://i.ibb.co/1dzmHNh/mask.png',
    skinName: 'accessories/cape-blue',
  },
  {
    image: 'https://i.ibb.co/YDMW1C9/steelgoogles.png',
    skinName: 'accessories/bag',
  },
  {
    image: 'https://i.ibb.co/yBD3FdQ/skarf.png',
    skinName: 'accessories/scarf',
  },
  {
    image: 'https://i.ibb.co/K7xZF6V/mustache.png',
    skinName: 'accessories/cape-red',
  },
  {
    image: 'https://i.ibb.co/GTDP1j3/cap.png',
    skinName: 'accessories/hat-red-yellow',
  },
];
