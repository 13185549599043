import { motion } from 'framer-motion';
import { ReactNode } from 'react';

interface FramerDragDetectorProps {
  children: ReactNode;
  className?: string;
  dragConstraints?: { left: number; right: number };
  dragElastic?: number;
  onChange: (direction: -1 | 1) => void;
}

export const FramerDragDetector: React.FC<FramerDragDetectorProps> = ({
  children,
  className,
  dragConstraints = { left: 0, right: 0 },
  dragElastic = 0.05,
  onChange,
}) => {
  return (
    <motion.div
      drag="x"
      dragConstraints={dragConstraints}
      dragElastic={dragElastic}
      dragSnapToOrigin
      onDragEnd={(e, { offset, velocity }) => {
        const swipe = swipePower(offset.x, velocity.x);

        if (swipe < -SWIPE_THRESHOLD) {
          onChange(1);
        } else if (swipe > SWIPE_THRESHOLD) {
          onChange(-1);
        }
      }}
      className={className}
    >
      {children}
    </motion.div>
  );
};

const SWIPE_THRESHOLD = 1000;
const swipePower = (offset: number, velocity: number) => {
  return Math.abs(offset) * velocity;
};
