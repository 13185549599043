import cn from 'classnames';

import { CoinsIndicator } from '../CoinsIndicator';
import { ReactComponent as InfoIcon } from '@icons/info.svg';
import { Button } from '@app/ui-kit';

import s from './CucumberBalance.module.scss';

interface CucumberBalanceProps {
  balance: number;
  animate: boolean;
  animateDuration?: number;
  className?: string;
}

export const CucumberBalance: React.FC<CucumberBalanceProps> = ({
  balance,
  animate,
  animateDuration = 1.5,
  className,
}) => {
  return (
    <div className={cn(s.root, className)}>
      <div className={s.label}>Cucumber Balance</div>
      <div id="cucumberBalanceAnimationTarget" className={s.animationTarget} />
      <CoinsIndicator
        variant="cucumber"
        value={balance}
        animateDuration={animateDuration}
        animateOnChange={animate}
        fontSize={48}
      />
      <Button
        className={s.link}
        btnType="link"
        variant="unfilled"
        size="large"
        rightIcon={<InfoIcon width={24} height={24} />}
        gap="4px"
        onClick={() => {
          // TODO
        }}
      >
        What are these
      </Button>
    </div>
  );
};
