import cn from 'classnames';
import { useState } from 'react';
import { AnimatePresence, m, motion } from 'framer-motion';

import {
  RankBadge,
  LeagueRankName,
  RankProgressBar,
  FramerDragDetector,
} from '@components';
import { Button } from '@app/ui-kit';
import { ReactComponent as InfoIcon } from '@icons/info.svg';
import { ReactComponent as ArrowRightIcon } from '@icons/arrow-right.svg';

import s from './LeaderboardRank.module.scss';

interface LeaderboardRankProps {
  currentSelectedLeagueIndex: number;
  leaguesAmount: number;
  rankNumber: number;
  rankName: string;
  isCurrentRank: boolean;
  currentUserProgress: number;
  navDirection: number;
  transitionDurationSeconds?: number;
  className?: string;
  onChange: (navDirection: 1 | -1) => void;
}

export const LeaderboardRank: React.FC<LeaderboardRankProps> = ({
  currentSelectedLeagueIndex,
  leaguesAmount,
  rankNumber,
  rankName,
  isCurrentRank,
  currentUserProgress,
  navDirection,
  transitionDurationSeconds = 0.5,
  className,
  onChange,
}) => {
  const prevDisabled = currentSelectedLeagueIndex <= 0;
  const nextDisabled = currentSelectedLeagueIndex >= leaguesAmount - 1;

  return (
    <div className={cn(s.root, className)}>
      <RankBadge
        level={rankNumber}
        className={s.rankBadge}
        transitionDurationSeconds={transitionDurationSeconds}
      />

      <AnimatePresence initial={false}>
        {isCurrentRank && (
          <m.div
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{ type: 'spring', duration: transitionDurationSeconds }}
            className={s.expBar}
          >
            <RankProgressBar
              currentProgress={currentUserProgress}
              max={7}
              variant="leagueProgress"
            />
          </m.div>
        )}
      </AnimatePresence>

      <FramerDragDetector
        onChange={onChange}
        dragElastic={0.1}
        className={s.nameWrapper}
      >
        <LeagueRankName
          rankName={rankName}
          transitionDurationSeconds={transitionDurationSeconds}
          navDirection={navDirection}
        />
        <button
          disabled={prevDisabled}
          onClick={() => onChange(-1)}
          className={cn(s.navButton, s.prev)}
        >
          <ArrowRightIcon className={s.arrow} />
        </button>
        <button
          disabled={nextDisabled}
          onClick={() => onChange(1)}
          className={cn(s.navButton, s.next)}
        >
          <ArrowRightIcon className={s.arrow} />
        </button>
      </FramerDragDetector>
      <Button
        btnType="link"
        variant="unfilled"
        size="large"
        gap="4px"
        rightIcon={<InfoIcon width={24} height={24} />}
        onClick={() => {
          // TODO: how rating is calculated
        }}
      >
        How rating is calculated
      </Button>
    </div>
  );
};
